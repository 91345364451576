import { styled } from 'config/stitches.config'

export const StyledSectionBuilder = styled('main', {
  width: '100%',
  variants: {
    darkMode: {
      true: { backgroundColor: '$primary', },
      false: { backgroundColor: '$background', },
    }
  },
  defaultVariants: { darkMode: false }

})
