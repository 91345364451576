const Section = (data, page) => {
  const { homepage } = page
  if (data.type === 'Hero' || data.type === 'Marquee' || data.type === 'Banner') return data

  const {
    type,
    inverted,
    indexLabel = null,
    title,
    subtitle,
    description,
    cta,
    descriptionStyle,
    sectionHide = false,
    ...otherProps
  } = data

  const theme =  inverted ? 'inverted' : 'default'
  const sectionGeneral = {
    theme,
    type,
    homepage,
  }

  if (sectionHide) Object.assign(sectionGeneral, { 'data-section-hide': sectionHide })

  if (!!indexLabel) {
    const indexLabelFormatted = indexLabel.toLowerCase().replace(/\s+/g, '-')
    Object.assign(sectionGeneral, {
      id: indexLabelFormatted,
      'data-theme': theme,
      'data-section-index': indexLabelFormatted,
    })
  }

  const sectionHeader = {
    title,
    subtitle,
    description,
    theme,
    descriptionStyle,
  }

  if (cta && !!cta.length)Object.assign(sectionHeader, { ctas: cta })

  const sectionContent = {
    type,
    ...otherProps
  }

  const normalizedData = {
    sectionHeader,
    sectionContent,
    sectionGeneral
  }

  return normalizedData
}

export default Section
