import React from 'react'
import {
  writeFile,
  readFile
} from 'utils/fs-helpers'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import { ENTITIES } from 'config'

import SectionBuilder from '@/SectionBuilder'

import {
  getPageData,
  getHeaderFooterData,
  getRedirects,
  getAllResultsFromEntity
} from 'services/API'

import {
  generateSlug,
  normalizeData,
  normalizeMetadata,
  normalizeAdditionalData,
} from 'services/API/helpers'

/**
 * You've just opened the project, you're here and you're confused.
 * It's normal, don't panic.
 *
 * All the pages for this website are built dynamically with the API data (the Strapi CMS)
 * All paths are build in the getStaticPaths function
 * Then, when the page is called (in our case, at build time) is executed the getStaticProps function
 *
 * Problem: Next doesn't pass other params that the one used as name of this file (in this case: slug)
 * Solution: create a cached filed in getStaticPaths with all useful data which is read in getStaticProps
 * https://github.com/vercel/next.js/discussions/11272#discussioncomment-102845
 */
const handleRedirects = async () => {
  let data = []
  try {
    const cachefile = await readFile('redirects.json', 'utf-8')
    console.log('🚨 Redirects already generated! Reading list from redirect.json file')
    data = JSON.parse(cachefile)
  } catch (error) {
    console.log('⚒️ Getting redirects from api !')
    data = await getRedirects()
    await writeFile('redirects.json', JSON.stringify([...data], null, 2), 'utf-8')
  }


  return data
}

export const getStaticPaths = async () => {
  const entitiesToGet = [
    {
      entity: ENTITIES,
      params: {
        locale: 'all',
        populate: 'deep'
      }
    }
  ]

  const entities = entitiesToGet.reduce((acc, curr) => {
    if (!Array.isArray(curr.entity)) return [...acc, curr]

    curr.entity.forEach(collection => {
      acc.push({
        entity: collection,
        params: curr.params
      })
    })

    return acc
  }, [])


  const pages = await Promise.all(entities.map(({
    entity,
    params
  }) => getAllResultsFromEntity({
    entity, params
  })))

  const redirects = await handleRedirects()
  const pagesPaths = pages.reduce((acc, data) => {
    if (!data) return acc

    const paths = data.map(({
      id,
      attributes
    }) => {
      return {
        params: {
          id,
          slug: generateSlug(attributes, redirects),
        },
        locale: attributes.locale.split('-')[0],
      }
    })
    return  [...acc, ...paths]
  }, [])

  try {
    const pagesCache = pages.reduce((acc, data) => {
      if (!data || data.length === 0) return acc

      const paths = data.map(({
        id,
        attributes,
        entity
      }) => {
        return {
          id,
          apiSlug: attributes.slug,
          slug: generateSlug(attributes, redirects),
          locale: attributes.locale,
          shortenedLocale: attributes.locale.split('-')[0],
          isHomepage: attributes.homepage,
          entity,
        }
      })


      return  [...acc, ...paths]
    }, [])

    await writeFile('static-paths.json', JSON.stringify([...pagesCache], null, 2), 'utf-8')
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Generate cache file error', error)
  }

  return {
    paths: [...pagesPaths],
    fallback: false
  }
}

export const getStaticProps = async ({
  params,
  locale: currentLocale
}) => {
  const cachefile = await readFile('static-paths.json', 'utf-8')
  const staticPaths = JSON.parse(cachefile)

  const {
    entity,
    locale,
    shortenedLocale,
    apiSlug
  } = staticPaths.find((path) => {
    if (params.slug) {
      const slug = Array.isArray(path.slug) ? path.slug.join('/') :  path.slug
      return  slug === params.slug.join('/') && path.shortenedLocale === currentLocale
    }

    return path.isHomepage && path.shortenedLocale === currentLocale
  })


  const [page, headerFooterData, redirects] = await Promise.all([
    getPageData({
      entity,
      slug: apiSlug,
      locale,
    }),
    getHeaderFooterData({ locale }),
    handleRedirects()
  ])

  const data = Array.isArray(page.data) ? page.data[0].attributes : page.data.attributes || {}
  const pageMeta = page.meta || {}
  const metadata = await normalizeMetadata(data, page.options)
  const additionalData = await normalizeAdditionalData(data, redirects)

  if (data.intro) {
    data.intro.asTitle = true
    data.sections = [
      data.intro,
      ...data.sections,
    ]
  }

  const sections =  await normalizeData(data.sections, shortenedLocale, additionalData, pageMeta)
  data.sections = sections

  return {
    props: {
      data,
      additionalData,
      menus: {
        header: headerFooterData.headerMenu || null,
        footer: headerFooterData.footerData || null,
      },
      options: page.options,
      metadata,
    }
  }
}

export default function Page({ data, }) {
  return (
    <SectionBuilder sections={data.sections} />
  )
}
